import { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const Countdown = lazy(() => import("./pages/Countdown"))

export default function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Countdown/>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}
